import React, {  } from "react"
import { Updates } from "src/assembled"
import { Footer } from "src/components"
import { SEO } from "src/components/utils"


export default function UpdatesPage({ location, navigate }) {
    // seo
    const seo = <SEO
    title="Updates"
    description="디어젠과 관련된 뉴스, 디어젠이 진행중인 파이프라인과 프로젝트에 관한 글을 소개합니다." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <Updates location={location} navigate={navigate} />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}
